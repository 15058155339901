import React from "react";
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect } from "react-redux";
import { getUsuario } from "../usuario/UsuarioService";
import axios from "axios";
import { URL, getAuthorizationHeader, idEmpresa, admin } from '../../constans/constants';
import Navbar from '../home/Navbar';
import "../../styles/Empresa.css";
import DocumentDialogContent from "../usuario/Usuario";
import UsuarioDatos from "../usuario/UsuarioDatos";
import MensajeFlash from '../mensajeFlash/MensajeFlash';
import { subirArchivoCsv } from "../usuario/UsuarioService";
import Cookies from 'universal-cookie';
import PantallaCarga from "../mensajeFlash/PantallaCarga";
import { Col, Container, Modal, Row, Button } from "react-bootstrap"
import Dropzone from 'react-dropzone';
import { logout } from "../login/SesionService";
import { obtenerListaEmpresas } from "../empresas/EmpresaService";
import { Form } from 'react-bootstrap';
import ExclamacionIcon from '../../styles/icons/exclamation-circle.svg'
import delete_forever from '../../styles/icons/delete.svg';
import edit from '../../styles/icons/edit.svg';
import cloud_upload from '../../styles/icons/cloud_upload.svg';

export const obtenerIdEmpresa = () => {
  const cookies = new Cookies();
  return cookies.get("empresa");
}
export const esSesionAdmin = () => {
  const cookies = new Cookies();
  return cookies.get("admin") == 'true';
}

class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogTitle: "Registrar Usuario",
      dialogType: "add",
      submitText: "Guardar",
      disabledInput: false,
      selectedUsuario: {
        usuario: "",
        identificador: "",
        nombres: "",
        apellidos: "",
        cedula: "",
        email: "",
        id: "",
      },
      file: null,
      filename: "",
      fileErrorMsg: "",
      dialogImportFromArchivo: false,
      dialogIsOpen: false,
      dialogIsOpenVerDatos: false,
      open: false,
      mostrarMensajeFlash: false,
      mensajeFlash: "",
      mensajeFlashSeverity: "success",
      roles: [],
      openPantallaCarga: false,
      severity: "success",
      listaEmpresas: null,
      listaEmpresaSelected: null,
      openMsgFailVentana: false,

    };
    this.tableRef = React.createRef();
    const cookies = new Cookies();
  }

  componentDidMount() {
    if (esSesionAdmin()) {
      obtenerListaEmpresas().then((res) => {
        this.state.listaEmpresas = res.datos;
        this.state.listaEmpresaSelected = res.datos[0].id
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  }

  handleDialog = (value, mensaje = "", severity) => {
    if (value === false) {
      this.tableRef.current.onQueryChange();  //Para actualizar la grilla
      if (mensaje != "") {
        this.setState({
          mostrarMensajeFlash: true,
          mensajeFlash: mensaje,
          severity: severity
        });
      }
    }
    this.setState({ dialogIsOpen: value });
  };

  handleDialogVerDatos = (value) => {
    this.setState({
      dialogIsOpenVerDatos: value,
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleCloseMensajeFlash = () => {
    this.setState({
      mostrarMensajeFlash: false,
    });

  };

  handleDialogImportarUsuarios = (estado) => {
    if (esSesionAdmin() && this.state.listaEmpresas == null) {
      this.setState({ openMsgFailVentana: true })
      return;
    }
    if (!estado) {
      this.setState({ file: null, fileErrorMsg: "", filename: "" })
    }
    this.setState({ dialogImportFromArchivo: estado })
  }

  handleChangeSelect = (e) => {
    this.setState({ listaEmpresaSelected: e.target.value })
  }
  descargarXls = () => {
    let empresa = esSesionAdmin() ? -200 : obtenerIdEmpresa();
    window.location.href = URL + "usuario/xls/" + empresa;
  }
  onDrop = (files) => {
    this.setState({
      file: files[0],
      filename: files[0].name,
      fileErrorMsg: ""
    })
  }

  sendFile = () => {
    if (this.state.file != null) {
      if (this.state.file.type != "text/csv") {
        this.setState({ fileErrorMsg: "* El archivo debe estar en formato .csv", file: this.state.file })
      } else {
        this.setState({ openPantallaCarga: true })
        var data = new FormData();
        data.append("file", this.state.file);

        this.props.subirArchivoCsv(data, admin() ? this.state.listaEmpresaSelected : idEmpresa()).then(res => {
          if (!res.error)
            this.setState({ mensajeFlash: res.mensaje, mostrarMensajeFlash: true, openPantallaCarga: false, })
          else
            this.setState({ mensajeFlash: res.mensaje, mostrarMensajeFlash: true, openPantallaCarga: false, severity: "danger" })
          this.handleDialogImportarUsuarios(false);
          this.tableRef.current.onQueryChange();
        })

      }
    } else {
      this.setState({ fileErrorMsg: "*Debe subir un archivo en formato .csv", file: this.state.file })
    }
  }

  handleDisableUsuario = () => {
    this.handleClose();
    this.setState({ openPantallaCarga: true });
    var empresa = obtenerIdEmpresa() != null ? obtenerIdEmpresa() : 0;
    axios
      .get(URL + "usuario/" + this.state.selectedUsuario.usuario + "/desactivar/empresa/" + empresa, getAuthorizationHeader())
      .then((response) => {
        this.setState({ openPantallaCarga: false });
        if (response.error) {
          this.setState({
            mensajeFlashSeverity: "danger"
          });
        } else {
          this.tableRef.current.onQueryChange();
        }
        this.setState({
          mensajeFlash: "Usuario deshabilitado con exito",
          mostrarMensajeFlash: true
        });
      }, error => {
        if (error.response.status === 401) {
          logout();
        }
        return error;
      });
  };

  handleNewUsuario = () => {
    this.setState(
      {
        dialogTitle: "Registrar nuevo Usuario",
        selectedUsuario: {
          usuario: "",
          identificador: "",
          nombres: "",
          apellidos: "",
          cedula: "",
          email: "",
          id: "",
        },
        dialogType: "add",
        submitText: "Guardar",
        disabledInput: false,
      },
      () => {
        this.handleDialog(true);
      }
    );
  };

  handleMsgFailVentana = () => {
    this.setState({ openMsgFailVentana: false })
  }

  render() {
    return (
      <div>
        <div className="dashboardContent">
          <div className="container-fluid">
            <PantallaCarga open={this.state.openPantallaCarga} />

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.openMsgFailVentana}
              backdrop="static"
              keyboard="false"
            >
              <Container>
                <Modal.Body>
                  {
                    <div>
                      <Row className="justify-content-center">
                        <Col className="text-center text-md-right">
                          <i className="bi bi-x-exclamation" style={{ fontSize: "8rem", color: "#F44336" }}></i>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col className="text-center text-md-right">
                          <p><strong>Para importar usuarios, debe agregar al menos una empresa primero </strong></p>
                        </Col>
                      </Row>
                    </div>
                  }
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => this.handleMsgFailVentana}>
                      Aceptar
                    </Button>
                  </Modal.Footer>
                </Modal.Body>
              </Container>
            </Modal>

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.open}
              onHide={this.handleClose}
              backdrop="static"
              keyboard="false"
            >
              <Container>
                <Modal.Body>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <img src={ExclamacionIcon} style={{ margin: '40px' }}></img>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="text-center text-md-right">
                      <p><strong>Estas Seguro que desea eliminar el Usuario seleccionado?</strong></p>
                    </Col>
                  </Row>
                  <Row style={{ background: "#EDF2F9" }}>
                    <Col>
                      <p><strong>Identificador:</strong> {this.state.selectedUsuario.identificador}</p>
                      <p><strong>Nombre de Usuario: </strong>{this.state.selectedUsuario.usuario}</p>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.handleClose} variant="contained">Cancelar</Button>
                  <Button onClick={this.handleDisableUsuario} color="primary" autoFocus>
                    Aceptar
                  </Button>
                </Modal.Footer>
              </Container>
            </Modal>
            <Modal
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogIsOpen}
              onHide={this.handleDialog}
              backdrop="static"
              keyboard="false"
            >
              <DocumentDialogContent
                usuario={this.state.selectedUsuario}
                handleClose={this.handleDialog}
                type={this.state.dialogType}
                submitText={this.state.submitText}
                disabledInput={this.state.disabledInput}
                dialogTitle={this.state.dialogTitle}
              />
            </Modal>

            <Modal
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.dialogImportFromArchivo}
              onHide={() => this.handleDialogImportarUsuarios(false)}
              backdrop="static"
              keyboard="false"
            >
              <Modal.Header>
                <Modal.Title style={{ color: "#0c56d0" }}>Importar lista de usuarios</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div>


                      <div className="carga-documento-section" style={{ border: this.state.fileErrorMsg.length == 0 ? '' : '1px solid red' }}>
                        <div className="carga-documento-content">
                          <img src={cloud_upload} alt="cloud_upload" />
                          <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                              <input {...getInputProps()} />
                              <p>Arrasta un archivo hasta aqui.</p>
                              <p className="text-small"> O si prefieres.. </p>
                              <a className="local-upload btn btn-lg btn-primary-outline" data-toggle="tooltip" data-placement="top"
                                data-original-title="Desktop"> Selecciona un archivo (solo en formato .csv)
                              </a>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <p className="form-date" style={{ color: "#3eba28" }}> {this.state.filename} </p>
                            </div>
                          </section>
                        </div>
                      </div>
                      <div style={{ marginTop: "1px" }}>
                        <small style={{ color: "#f93154", alignContent: 'left' }}>{this.state.fileErrorMsg}</small>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {
                  (esSesionAdmin && this.state.listaEmpresas != null) &&
                  <div style={{ marginTop: "8px" }}>
                    <Form.Group className="form-group md-input">
                      <Form.Select className="form-control md-select" name="idGrupo" id="idGrupo"
                        onChange={this.handleChangeSelect} value={this.state.listaEmpresaSelected} defaultValue={this.state.listaEmpresaSelected} required>
                        {this.state.listaEmpresas.map((elemento) => (
                          <option value={elemento.id} key={elemento.id}>{elemento.nombre}</option>
                        ))}
                      </Form.Select>
                      <Form.Label class="md-label"> Seleccione una empresa </Form.Label>
                    </Form.Group>
                  </div>
                }
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.handleDialogImportarUsuarios(false)} variant="contained" color="primary">
                  Cancelar
                </Button>
                <Button autoFocus color="primary" onClick={this.sendFile} >
                  Importar archivo
                </Button>
              </Modal.Footer>

            </Modal>
            <MensajeFlash
              mensaje={this.state.mensajeFlash}
              severity={this.state.severity}
              open={this.state.mostrarMensajeFlash}
              handleClose={this.handleCloseMensajeFlash} />
            <div className="sectionHeader">
              <h1 className="pageTitle"> Usuarios </h1>
              <div className="actions">
                <Button
                  style={{ backgroundColor: "#00B1C9" }}
                  onClick={this.handleNewUsuario}
                >
                  Nuevo Usuario
                </Button>
                <Button
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  onClick={() => this.handleDialogImportarUsuarios(true)}
                >
                  Importar desde archivo
                </Button>
                <Button
                  variant="success"
                  onClick={this.descargarXls}
                >
                  Descargar XLS
                </Button>
              </div>
            </div>
            <Navbar
              menuActual={window.location.hash}
              seccionActual={'administracion'}
            />

            <div className="contentPage card" style={{ padding: '0px' }}>
              <MaterialTable
                style={{ borderRadius: ".5rem" }}
                tableRef={this.tableRef}
                detailPanel={rowData => {
                  return (
                    <UsuarioDatos
                      usuario={rowData} />
                  )
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                columns={[
                  {
                    title: "Nombres y Apellidos", field: "nombreApellido", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Usuario", field: "usuario", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "C.I.", field: "cedula", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Email", field: "email", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  },
                  {
                    title: "Identificador", field: "identificador", headerStyle: {
                      fontFamily: "Open Sans", fontWeight: "bold"
                    }
                  }
                ]}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    this.props.getUsuario(query.page, query.pageSize, query.search).then(() => {
                      resolve({
                        data: this.props.usuariosData.usuarios,
                        page: this.props.usuariosData.page,
                        totalCount: this.props.usuariosData.totalCount,
                      });
                    });
                  })
                }
                actions={[
                  {
                    icon: () => <img src={edit} alt='Editar' />,
                    tooltip: 'Editar datos',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Editar Usuario",
                        selectedUsuario: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.setState({ mostrarMensajeFlash: false });
                        this.handleDialog(true);
                      });
                    }
                  },
                  {
                    icon: () => <img src={delete_forever} alt='Eliminar' />,
                    tooltip: 'Eliminar',
                    onClick: (event, rowData) => {
                      this.setState({
                        dialogTitle: "Ver Aplicación Externa",
                        selectedUsuario: rowData,
                        dialogType: "edit",
                        submitText: "Editar",
                        disabledInput: false
                      }, () => {
                        this.handleClickOpen();
                      });
                    }
                  }
                ]}
                options={{
                  actionsColumnIndex: -1,
                  sorting: false,
                  paginationType: "stepped",
                  search: true,
                  showTextRowsSelected: false,
                  showTitle: false,
                  toolbar: true,
                  detailPanelColumnAlignment: "right",
                  searchFieldVariant: "standard",
                  searchFieldAlignment: "right",
                  headerStyle: {
                    background: "#edf2f9",
                    fontFamily: "Open Sans", fontWeight: "bold",
                  }
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: "No hay resultados que mostrar",
                  },
                  header: {
                    actions: "Acciones",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} de {count}",
                    nextTooltip: "Página Siguiente",
                    previousTooltip: "Página Anterior",
                    firstTooltip: "Primera Página",
                    lastTooltip: "Última Página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    searchPlaceholder: "Buscar",
                    searchTooltip: "Buscar",
                  },

                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsuario: (page, size, search) => dispatch(getUsuario(page, size, search)),
  subirArchivoCsv: (file, idEmpresa) => dispatch(subirArchivoCsv(file, idEmpresa))
});

const mapStateToProps = (state) => ({
  usuariosData: state.usuariosData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
